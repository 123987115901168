import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
export default function Landing() {
  const [state, setState] = useState(true);
  const ref = useRef();
  function scrollToView() {
    console.log("sdadasdsadsa", ref);
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1581056771107-24ca5f033842?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-4xl">
                    Consult top doctors online for any health concern
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    You can book appointments with top doctors online to discuss
                    your health concerns. They will provide you with expert
                    advice and information about your condition.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div
                className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
                onClick={scrollToView}
              >
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i class="fas fa-calendar-check"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Book Appointment</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Schedule a consultation with esteemed doctors online to
                      address your health concerns. Enjoy the convenience of
                      virtual appointments from the comfort of your own home.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="w-full md:w-4/12 px-4 text-center"
                onClick={scrollToView}
              >
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i class="fa fa-user-circle"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Consult with Doctor
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Get expert medical advice from top doctors. Discuss your
                      health concerns, receive valuable information about your
                      condition.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="pt-6 w-full md:w-4/12 px-4 text-center"
                onClick={scrollToView}
              >
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i class="fas fa-plus"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Proceed for Treatment
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Take the next step towards treatment by initiating the
                      process. Access the necessary medical care and guidance
                      for your health condition.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div
                className="w-full md:w-5/12 px-4 mr-auto ml-auto"
                onClick={scrollToView}
              >
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i class="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Working for you is a pleasure
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Schedule virtual appointments with leading medical experts to
                  address your health issues.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  Access top doctors online for expert healthcare consultations.
                  Get guidance, advice, and support for your health concerns
                  conveniently from home. Take proactive steps towards your
                  well-being by scheduling a consultation and accessing the care
                  you need.
                </p>
                <Link to="/" className="font-bold text-blueGray-700 mt-8">
                  Check Yujo24!
                </Link>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1666887360921-85952a86894f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Top Notch Doctors
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      We have highly skilled doctors from various fields.
                      Whether you need a specialist or general practitioner,
                      we've got you covered. Book an appointment and receive
                      expert medical care for your specific needs.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1550831107-1553da8c8464?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i class="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Easy treatment</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Our Doctors will Provide the right direction for the
                    treatment.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i class="fa fa-child" aria-hidden="true"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Get consultation for 50+ diseases across India
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i class="fa fa-user-md"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Online consultation with experienced doctors
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i class="fa fa-heartbeat" aria-hidden="true"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Medical assistance throughout your treatment
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-12 pb-40">
          <div className="container mx-auto px-4 ">
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-full lg:w-4/12">
                <div>
                  <img
                    alt="..."
                    src={require("assets/img/leftView.png").default}
                    className="max-w-420-px "
                  />
                </div>
              </div>
              <div className="lg:w-4/12">
                <div className="flex flex-wrap justify-center text-center ">
                  <div className=" lg:w-1/12 px-4 mt-10 mb-8">
                    <h2 className="text-4xl font-semibold">Join Us</h2>
                    <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                      "A healthy outside starts from the inside." - Robert Urich
                    </p>
                  </div>
                </div>
                <div className="flex mb-24 justify-center">
                  <div className="lg:w-2/12 mr-2">
                    <div>
                      <img
                        alt="..."
                        src={require("assets/img/AppstoreIcon.png").default}
                        className="max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="lg:w-2/12 ml-2">
                    <div>
                      <img
                        alt="..."
                        src={require("assets/img/googlePlayIcon.png").default}
                        className="max-w-150-px"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-4/12">
                <div>
                  <img
                    alt="..."
                    src={require("assets/img/rightView.png").default}
                    className="max-w-420-px"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i class="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excelent Services
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Our team of experienced doctors is available for provide you
                  with the best possible treatment.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i class="fas fa-hourglass-half text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  24/7 Available
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  We are always available to our patients, 24 hours a day, 7
                  days a week.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i class="fas fa-phone text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Video/Audio Chat
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  We offer a variety of consultation options, including video
                  chat, phone calls, and email.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-20 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4 py-20" ref={ref}>
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  {state ? (
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="text-2xl font-semibold">
                        Reach out to us
                      </h4>
                      <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                        Complete this form and we will get back to you in few
                        minutes.
                      </p>
                      <Formik
                        initialValues={{
                          fullName: "",
                          email: "",
                          phone: "",
                          concern: "",
                        }}
                        // . . .
                        onSubmit={(values, { setSubmitting }) => {
                          if (
                            values.fullName != "" &&
                            values.phone != "" &&
                            values.email != "" &&
                            values.concern != ""
                          ) {
                            setState(false);
                            setSubmitting(true);
                            toast.success("Your Details saved successfully");
                            fetch(
                              "https://api.aarogyabharat.live/medicon/v1/media/sendData",
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  fullName: values.fullName,
                                  phone: values.phone.toString(),
                                  concern: values.concern,
                                  email: values.email,
                                }),
                              }
                            )
                              .then((res) => res.json())
                              .then((json) => {
                                setSubmitting(false);
                              });
                          } else {
                            console.log("dasdasddasda");
                            setSubmitting(false);
                            // alert("field should not be empty!");
                            toast.error("field should not be empty!");
                          }
                        }}
                      >
                        {({
                          isSubmitting,
                          handleChange,
                          handleSubmit,
                          values,
                        }) => (
                          <div>
                            <div className="relative w-full mb-3 mt-8">
                              <div className="flex justify-between">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr-2"
                                  htmlFor="full-name"
                                >
                                  Full Name
                                </label>
                                {/* <label
                                  className="block text-red-500 text-xs font-semibold mb-2"
                                  htmlFor="full-name"
                                >
                                  *Required
                                </label> */}
                              </div>

                              <input
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Full Name"
                                onChange={handleChange("fullName")}
                              />
                            </div>

                            <div className="relative w-full mb-3 ">
                              <div className="flex justify-between">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr-2"
                                  htmlFor="email"
                                >
                                  Phone
                                </label>
                                {/* <label
                                  className="block text-red-500 text-xs font-semibold mb-2"
                                  htmlFor="full-name"
                                >
                                  *Required
                                </label> */}
                              </div>
                              <input
                                type="number"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Phone"
                                maxLength={10}
                                onChange={handleChange("phone")}
                              />
                            </div>
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                onChange={handleChange("email")}
                              />
                            </div>
                            <div className="relative w-full mb-3">
                              <div className="flex justify-between">
                                <label
                                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mr-2"
                                  htmlFor="message"
                                >
                                  Concern
                                </label>
                                {/* <label
                                  className="block text-red-500 text-xs font-semibold mb-2"
                                  htmlFor="full-name"
                                >
                                  *Required
                                </label> */}
                              </div>

                              <textarea
                                rows="4"
                                cols="80"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Type a message..."
                                onChange={handleChange("concern")}
                              />
                            </div>
                            <div className="text-center mt-6">
                              <button
                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                              >
                                Send To Us
                              </button>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="text-2xl font-semibold">
                        Thank You For Reaching out to us
                      </h4>
                      <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                        Our Team will connect you sortly.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Toaster />
      <Footer />
    </>
  );
}
