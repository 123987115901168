import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
export default function PrivacyPolicies() {
  const [terms, setTerms] = useState([]);
  useEffect(() => {
    fetch("https://api.aarogyabharat.live/medicon/v1/media/terms", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("json", json);
        setTerms(json.data.terms);
      });
  }, []);
  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: 130,
          marginRight: 50,
          marginLeft: 50,
          marginBottom: 50,
        }}
      >
        <div
          className=""
          style={{
            color: "#000000",
            fontSize: 22,
          }}
        >
          {terms?.top_heading}
        </div>
        <div style={{ marginTop: 4 }}>
          {terms?.points?.map((item, index) => {
            return (
              <>
                <div
                  style={{
                    color: "#000000",
                    marginTop: 12,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {index + 1}. {item.title}:{" "}
                  <div
                    style={{
                      color: "#525A66",
                      fontSize: 16,
                      fontWeight: "normal",
                      marginLeft: 40,
                    }}
                  >
                    {item.value}
                  </div>
                </div>
                {item.values?.map((item2, index) => {
                  return (
                    <>
                      <div
                        style={{
                          marginTop: 8,
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: "700",
                          marginLeft: 40,
                        }}
                      >
                        {item2.sub_heading}:{" "}
                        <div
                          style={{
                            color: "#525A66",
                            fontSize: 16,
                            fontWeight: "normal",
                            marginLeft: 40,
                          }}
                        >
                          {item2.sub_value}
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
        <div
          style={{
            color: "#525A66",
            marginTop: 8,
            fontSize: 20,
          }}
        >
          {terms?.bottom_part1}
        </div>
        <div
          style={{
            marginTop: 8,
            color: "#525A66",
            fontSize: 20,
          }}
        >
          {terms?.bottom_part2}
        </div>
      </div>
    </>
  );
}
